export function loadModule(moduleSrc, noModuleSrc) {
    // For new browsers
    if (!document.querySelector(`script[src$="${moduleSrc}"]`)) {
      const node = document.createElement('script');
      node.type = 'module';
      node.src = moduleSrc;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
    // For older browsers
    if(!document.querySelector(`script[src$="${noModuleSrc}"]`)){
      const node = document.createElement('script');
      node.noModule = true;
      node.src = noModuleSrc;
      node.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

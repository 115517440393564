import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../actions/user.actions';
import { ApiError } from '@shared-models/api-error';
import { User } from '@shared-models/user';

export interface State {
    user: User;
    userLoading: boolean;
    userError: ApiError;
    validators: User[];
}

export const initialState = {
    user: undefined,
    userLoading: false,
    userError: undefined,
    validators: undefined
} as State;

const userReducer = createReducer(
    initialState,
    on(UserActions.GetUser, (state, action) => ({
        ...state,
        userLoading: true
    })),
    on(UserActions.GetUserSuccess, (state, action) => ({
        ...state,
        user: action.payload,
        userLoading: false
    })),
    on(UserActions.GetUserFailure, (state, action) => ({
        ...state,
        userError: action.payload,
        userLoading: false
    })),
    on(UserActions.GetValidators, (state, action) => ({
        ...state,
    })),
    on(UserActions.GetValidatorsSuccess, (state, action) => ({
        ...state,
        validators: action.payload
    })),
    on(UserActions.GetValidatorsFailure, (state, action) => ({
        ...state,
        validators: null
    })),
    on(UserActions.ClearValidatorListData, (state, action) => ({
        ...state,
        validators: undefined
    }))
);
export function reducer(state: State | undefined, action: Action) {
    return userReducer(state, action);
}

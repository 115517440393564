import { Component, Inject, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessModalData } from './success-modal-data';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})

export class SuccessModalComponent implements OnInit{
  faTimes = faTimes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SuccessModalData,
    private dialogRef: MatDialogRef<SuccessModalComponent>
  ) { }

  ngOnInit() {
    if (!this.data.keepModalOpen) {
      setTimeout(() => {
        this.dialogRef.close();
      }, 3500);
    }
  }
}

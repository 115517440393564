import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

// Pages/Declarations
import { HomeComponent } from './home/home.component';
import { PagesComponent } from './pages.component';
import { OriginatorHomeComponent } from '@shared-components/originator/originator-home/originator-home.component';
import { ValidatorHomeComponent } from '@shared-components/validator/validator-home/validator-home.component';
import { FileSelectionComponent } from '@shared-components/file-selection/file-selection.component';

// Modules
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { FileUploadDirective } from '@shared-components/file-upload/directives/file-upload.directive';
import { FileUploadComponent } from '@shared-components/file-upload/file-upload.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PartNumberLandingPageComponent } from './part-number-landing-page/part-number-landing-page.component';
import { PartNumberDetailsComponent } from '@shared-components/part-number-details/part-number-details.component';
import { FileReviewSectionComponent } from '../shared/components/file-review-section/file-review-section.component';
import { MatCardModule } from '@angular/material/card';
import { ActionDialogComponent } from '@shared-components/action-dialog/action-dialog.component';
import { SuccessModalComponent } from '@shared-components/success-modal/success-modal.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FailureModalComponent } from '@shared-components/failure-modal/failure-modal.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const COMPONENTS = [
  PagesComponent,
  HomeComponent,
  OriginatorHomeComponent,
  FileSelectionComponent,
  FileUploadComponent,
  FileUploadDirective,
  ValidatorHomeComponent,
  PartNumberLandingPageComponent,
  PartNumberDetailsComponent,
  FileReviewSectionComponent,
  ActionDialogComponent,
  SuccessModalComponent,
  FailureModalComponent
];

const MODULES = [
  RouterModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatSelectModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  MatCheckboxModule,
  FontAwesomeModule,
  MatCardModule,
  MatPaginatorModule,
  MatProgressBarModule
];

@NgModule({
    declarations: [
      COMPONENTS
    ],
    imports: [
      MODULES,
    ],
    providers: [],
    exports: [MODULES],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  })

export class PagesModule { }

<div class="success-modal__container">
    <div class="success-modal__wrapper">
        <div class="success-modal__header">{{ data?.title }}</div>
        <div class="success-modal__close-icon">
            <fa-icon 
                [icon]="faTimes" 
                mat-dialog-close 
                [mat-dialog-close]='false'>
            </fa-icon>
        </div> 
    </div>
    <div class="success-modal__body" *ngIf="data?.body">{{ data?.body }}</div>
</div>
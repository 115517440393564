import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as store from '@shared-store/app.store';
import * as UserActions from '@shared-store/actions/user.actions';
import * as UserSelectors from '@shared-store/selectors/user-selectors';

@Injectable()
export class UserSandboxService {
    user$ = this.appStore.select(UserSelectors.selectUser);
    userError$ = this.appStore.select(UserSelectors.selectUserError);
    validators$ = this.appStore.select(UserSelectors.selectValidators);
    userLoading$ = this.appStore.select(UserSelectors.selectUserLoading);

    constructor(
        private appStore: Store<store.State>,
    ) { }

    getUser() {
        this.appStore.dispatch(UserActions.GetUser());
    }

    getValidators() {
        this.appStore.dispatch(UserActions.GetValidators());
    }

    clearValidatorListData() {
        this.appStore.dispatch(UserActions.ClearValidatorListData());
    }
}

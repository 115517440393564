<div class="part-number-details__container">
    <div class="part-number-details__container-header">
    <h2 class="part-number-details__container-title type__h3--alt">Part Number Details</h2>
    <a class="part-number-details__container-upload-link" (click)="openFileSelection()">Upload Files to Associate</a>
</div>
    <div class="part-number-details__container-part-info">
        <div class="part-number-details__container-part-info-content">
            <div class="part-number-details__container-part-info-content-label">PART NUMBER</div>
            <div class="part-number-details__container-part-info-content-label-value">{{ partNumberRevision?.partNumber }}</div>
        </div>
        <div class="part-number-details__container-part-info-content">
            <div class="part-number-details__container-part-info-content-label">PART NAME</div>
            <div class="part-number-details__container-part-info-content-label-value">{{ partNumberRevision?.partName }}</div>
        </div>
        <div class="part-number-details__container-part-info-content">
            <div class="part-number-details__container-part-info-content-label">DESCRIPTION</div>
            <div class="part-number-details__container-part-info-content-label-value">{{ partNumberRevision?.productDescription }}</div>
        </div>

        <div class="part-number-details__container-part-info-content-revision">
            <div class="part-number-details__container-part-info-content-label">PRODUCT REVISION</div>
            <div class="part-number-details__container-part-info-content-label-value">{{ partNumberRevision?.productRevision }}</div>
        </div>
    </div>
</div>

<div *ngIf="section === FileReviewSectionType.PendingFiles">
  <ng-container *ngTemplateOutlet="pendingFilesContent"></ng-container>
</div>
<div *ngIf="section === FileReviewSectionType.FilesToValidate">
    <ng-container *ngTemplateOutlet="filesToValidateContent"></ng-container>
</div>
<div *ngIf="section === FileReviewSectionType.FilesOriginatedPendingValidation">
    <ng-container *ngTemplateOutlet="filesOriginatedPendingValidationContent"></ng-container>
</div>
<div *ngIf="section === FileReviewSectionType.ActiveAssociatedFiles">
    <ng-container *ngTemplateOutlet="activeAssociatedFilesContent"></ng-container>
</div>
<div *ngIf="section === FileReviewSectionType.PastAssociatedFiles">
    <ng-container *ngTemplateOutlet="pastAssociateFilesContent"></ng-container>
</div>

<ng-template #pendingFilesContent>
  <mat-card appearance="outlined">
    <ilmn-loader variant="spinner-medium" [active]="showLoader"></ilmn-loader>
    <div class="file-review-section__container">
      <h2 class="file-review-section__container-title type__h3--alt">Validator</h2>
      <div class="file-review-section__content">
          <div *ngIf="pendingFiles?.data?.fileList?.length > 0" class="file-review-section__content-description">
            You can approve/reject file associations.  
            Below is a list of files pending validations for you to review.
          </div>  
          <div *ngIf="pendingFiles?.data?.fileList?.length === 0" class="file-review-section__content-no-validations">
            No pending validations for you to review
          </div>     
      </div>
      <div *ngIf="pendingFiles?.data?.fileList?.length > 0" class="file-review-section__content-results">
          <div class="file-review-section__content-results-header">
            <span class="file-review-section__content-results">File Name</span>
            <span class="file-review-section__content-results files-to-be-replaced-validate">File to be Replaced</span>
            <span class="file-review-section__content-results">Action</span>
            <span class="file-review-section__content-results originator-validate">Originator</span>
            <span class="file-review-section__content-results originated-on-validate">Originated On</span>
            <span class="file-review-section__content-results action-checkbox">Action</span>
          </div>
          <form [formGroup]="fileFormGroup">
            <div *ngFor="let pValidation of pendingFiles?.data?.fileList" class="file-review-section__content-results-row cta">
              <span class="file-review-section__content-results">{{ pValidation.fileName }}</span>
              <span class="file-review-section__content-results files-to-be-replaced-validate">{{ pValidation.replacedFileName }}</span>
              <span class="file-review-section__content-results">{{ pValidation.action }}</span>
              <span class="file-review-section__content-results originator-validate">{{ pValidation.originator }}</span>
              <span class="file-review-section__content-results originated-on-validate">{{ formatDate(pValidation.date) }}</span>
              <span class="file-review-section__content-results action-checkbox">
                <mat-checkbox (change)="onChangeFileFormGroup($event)" [value]="pValidation" [checked]="selection.isSelected(pValidation.fileId)"></mat-checkbox>
              </span>
            </div>
          </form>
          <div *ngIf="pendingFiles?.data?.fileList?.length" class="file-review-section__paginator bottom-border">
            <mat-paginator [length]="pendingFiles?.data.fileCount"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [hidePageSize]="true"
                (page)="onPendingFilePageChange($event)">
            </mat-paginator>
        </div>
      </div>      
      <div *ngIf="pendingFiles?.data?.fileList?.length > 0" class="file-review-section__content-button-container">
        <button mat-flat-button color="primary" [disabled]="disableActionButton" (click)="submitFilesToValidateForApproval()">Approve</button>
        <button mat-stroked-button [ngClass]="{'secondary': !disableActionButton}" [disabled]="disableActionButton" (click)="openActionDialogForReject()">Reject</button>
      </div>
  </div>
  </mat-card>
</ng-template>

<ng-template #filesToValidateContent>
  <mat-card appearance="outlined">
    <ilmn-loader variant="spinner-medium" [active]="showLoader"></ilmn-loader>
    <div class="file-review-section__container">
      <h2 class="file-review-section__container-title type__h3--alt">{{ section }}</h2>
      <div class="file-review-section__content">
          <div *ngIf="filesToValidate?.data?.fileList?.length === 0" class="file-review-section__content-no-validations">
              No files to validate
          </div>     
      </div>
      <div *ngIf="filesToValidate?.data?.fileList?.length > 0" class="file-review-section__content-results">
          <div class="file-review-section__content-results-header">
            <span class="file-review-section__content-results">File Name</span>
            <span class="file-review-section__content-results files-to-be-replaced-validate">File to be Replaced</span>
            <span class="file-review-section__content-results">Action</span>
            <span class="file-review-section__content-results originator-validate">Originator</span>
            <span class="file-review-section__content-results originated-on-validate">Originated On</span>
            <span class="file-review-section__content-results action-checkbox">Action</span>
          </div>
          <form [formGroup]="fileFormGroup">
            <div *ngFor="let pValidation of filesToValidate?.data?.fileList" class="file-review-section__content-results-row cta">
              <span class="file-review-section__content-results">{{ pValidation.fileName }}</span>
              <span class="file-review-section__content-results files-to-be-replaced-validate">{{ pValidation.replacedFileName }}</span>
              <span class="file-review-section__content-results">{{ pValidation.action }}</span>
              <span class="file-review-section__content-results originator-validate">{{ pValidation.originator }}</span>
              <span class="file-review-section__content-results originated-on-validate">{{ formatDate(pValidation.date) }}</span>
              <span class="file-review-section__content-results action-checkbox">
                <mat-checkbox (change)="onChangeFileFormGroup($event)" [value]="pValidation" [checked]="selection.isSelected(pValidation.fileId)"></mat-checkbox>
              </span>
            </div>
          </form>
          <div *ngIf="filesToValidate?.data?.fileList?.length" class="file-review-section__paginator bottom-border">
            <mat-paginator [length]="filesToValidate?.data.fileCount"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [hidePageSize]="true"
                (page)="onValidationPageChange($event)">
            </mat-paginator>
        </div>
      </div>      
      <div *ngIf="filesToValidate?.data?.fileList?.length > 0" class="file-review-section__content-button-container">
        <button mat-flat-button color="primary" [disabled]="disableActionButton" (click)="submitFilesToValidateForApproval()">Approve</button>
        <button mat-stroked-button [ngClass]="{'secondary': !disableActionButton}" [disabled]="disableActionButton" (click)="openActionDialogForReject()">Reject</button>
      </div>
  </div>
  </mat-card>
</ng-template>

<ng-template #filesOriginatedPendingValidationContent>
  <mat-card appearance="outlined">
    <ilmn-loader variant="spinner-medium" [active]="showLoader"></ilmn-loader>
    <div class="file-review-section__container">
        <h2 class="file-review-section__container-title type__h3--alt">{{ section }}</h2>
        <div class="file-review-section__content">
            <div *ngIf="filesOriginatedPendingValidation?.data?.fileList?.length === 0" class="file-review-section__content-no-validations">
              No files originated pending validation
            </div>     
        </div>
        <div *ngIf="filesOriginatedPendingValidation?.data?.fileList?.length > 0" class="file-review-section__content-results">
            <div class="file-review-section__content-results-header">
              <span class="file-review-section__content-results">File Name</span>
              <span class="file-review-section__content-results files-to-be-replaced">File to be Replaced</span>
              <span class="file-review-section__content-results-">Action</span>
              <span class="file-review-section__content-results validator-pending">Validator</span>
              <span class="file-review-section__content-results">Originated On</span>
            </div>
            <div *ngFor="let pValidation of filesOriginatedPendingValidation?.data?.fileList" class="file-review-section__content-results-row">
              <span class="file-review-section__content-results">{{ pValidation.fileName }}</span>
              <span class="file-review-section__content-results files-to-be-replaced">{{ pValidation.replacedFileName }}</span>
              <span class="file-review-section__content-results-">{{ pValidation.action }}</span>
              <span class="file-review-section__content-results validator-pending">{{ pValidation.validator }}</span>
              <span class="file-review-section__content-results">{{ formatDate(pValidation.date) }}</span>
            </div>
            <div *ngIf="filesOriginatedPendingValidation?.data?.fileList?.length" class="file-review-section__paginator">
              <mat-paginator [length]="filesOriginatedPendingValidation?.data.fileCount"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [hidePageSize]="true"
                  (page)="onOriginatorPageChange($event)">
              </mat-paginator>
          </div>
        </div>      
    </div>
  </mat-card>
</ng-template>

<ng-template #activeAssociatedFilesContent>
  <mat-card appearance="outlined">
    <ilmn-loader variant="spinner-medium" [active]="showLoader"></ilmn-loader>
    <div class="file-review-section__container">
        <h2 class="file-review-section__container-title type__h3--alt">{{ section }}</h2>
        <div class="file-review-section__content">
            <div *ngIf="activeAssociatedFiles?.data?.fileList?.length === 0" class="file-review-section__content-no-validations">
                No active associated files
            </div>     
        </div>
        <div *ngIf="activeAssociatedFiles?.data?.fileList?.length > 0" class="file-review-section__content-results">
            <div class="file-review-section__content-results-header">
              <span class="file-review-section__content-results">File Name</span>
              <span class="file-review-section__content-results originator-active">Originator</span>
              <span class="file-review-section__content-results associated-on-active">Associated On</span>
              <span class="file-review-section__content-results replace-active">Replace</span>
              <span class="file-review-section__content-results obsolete-active">Obsolete</span>
            </div>
            <div *ngFor="let pValidation of activeAssociatedFiles?.data?.fileList" class="file-review-section__content-results-row">
              <span class="file-review-section__content-results">{{ pValidation.fileName }}</span>
              <span class="file-review-section__content-results originator-active">{{ pValidation.originator }}</span>
              <span class="file-review-section__content-results">{{ formatDate(pValidation.date) }}</span>
              <div class="file-review-section__content-link-container">
                <span class="file-review-section__content-results action-active-files"><a (click)="openFileSelectionForReplace(pValidation)">Replace</a></span>
                <span class="file-review-section__content"><a (click)="openActionDialogForObsolete(pValidation)">Make Obsolete</a></span>
              </div>
            </div>
            <div *ngIf="activeAssociatedFiles?.data?.fileList?.length" class="file-review-section__paginator">
              <mat-paginator [length]="activeAssociatedFiles?.data?.fileCount"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [hidePageSize]="true"
                  (page)="onactiveAssociatedFilesPageChange($event)">
              </mat-paginator>
          </div>  
        </div>      
    </div>
  </mat-card>
</ng-template>

<ng-template #pastAssociateFilesContent>
  <mat-card appearance="outlined">
    <ilmn-loader variant="spinner-medium" [active]="showLoader"></ilmn-loader>
    <div class="file-review-section__container">
        <h2 class="file-review-section__container-title type__h3--alt">{{ section }}</h2>
        <div class="file-review-section__content">
            <div *ngIf="pastAssociatedFiles?.data?.fileList?.length === 0" class="file-review-section__content-no-validations">
                No past associated files
            </div>     
        </div>
        <div *ngIf="pastAssociatedFiles?.data?.fileList?.length > 0" class="file-review-section__content-results">
            <div class="file-review-section__content-results-header">
              <span class="file-review-section__content-results">File Name</span>
              <span class="file-review-section__content-results-past-associated-originator">Originator</span>
              <span class="file-review-section__content-results">Obsoleted On</span>
            </div>
            <div *ngFor="let pValidation of pastAssociatedFiles?.data?.fileList" class="file-review-section__content-results-row">
              <span class="file-review-section__content-results">{{ pValidation.fileName }}</span>
              <span class="file-review-section__content-results-past-associated-originator">{{ pValidation.originator }}</span>
              <span class="file-review-section__content-results">{{ formatDate(pValidation.date) }}</span>
            </div>
            <div *ngIf="pastAssociatedFiles?.data?.fileList?.length" class="file-review-section__paginator">
              <mat-paginator [length]="pastAssociatedFiles?.data?.fileCount"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [hidePageSize]="true"
                  (page)="onPastAssociatedFilesPageChange($event)">
              </mat-paginator>
          </div>
        </div>      
    </div>
  </mat-card>
</ng-template>
import { Component, Inject } from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FailureModalData } from './failure-modal-data';

@Component({
  selector: 'app-failure-modal',
  templateUrl: './failure-modal.component.html',
  styleUrls: ['./failure-modal.component.scss']
})

export class FailureModalComponent {
  faTimes = faTimes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FailureModalData,
  ) { }

}

import { createAction, props } from '@ngrx/store';
import { ApiError } from '@shared-models/api-error';
import { User } from '@shared-models/user';


export const GetUser = createAction('[USER] Getting user');
export const GetUserSuccess = createAction('[USER] Successfully got user', props<{ payload: User }>());
export const GetUserFailure = createAction('[USER] Failed to get user', props<{ payload: ApiError }>());

export const GetValidators = createAction('[USER] Getting validators');
export const GetValidatorsSuccess = createAction('[USER] Successfully got validators', props<{ payload: User[] }>());
export const GetValidatorsFailure = createAction('[USER] Failed to get validators', props<{ payload: ApiError }>());

export const ClearValidatorListData = createAction('[USER] Clear ClearValidatorListData');

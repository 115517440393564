import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { RouteGuard } from './shared/services/route-guard.service';

// Components
import { PagesComponent } from './pages/pages.component';
import { HomeComponent } from './pages/home/home.component';
import { PartNumberLandingPageComponent } from './pages/part-number-landing-page/part-number-landing-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/pages/home', pathMatch: 'full' },
  { path: 'part-number-landing-page', redirectTo: '/pages/part-number-landing-page', pathMatch: 'full' },
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      { path: 'home', component: HomeComponent, canActivate: [RouteGuard], title: 'Home' },
      { path: 'part-number-landing-page', component: PartNumberLandingPageComponent, canActivate: [RouteGuard], title: 'Part Number Landing Page'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [AuthService]
})

export class AppRoutingModule { }

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PartNumberRevisionSandboxService } from '@shared-services/part-number-revision-sandbox.service';
import { takeUntil } from 'rxjs/operators';
import { FileAssociation } from '@shared-models/file-association';
import { ActionType } from '@shared-models/action-type';
import { FileReviewSectionType } from '@shared-models/file-review-section-type';
import { PaginationRequest } from '@shared-models/pagination-request';
import { User } from '@shared-models/user';

@Component({
  selector: 'app-validator-home',
  templateUrl: './validator-home.component.html',
  styleUrls: ['./validator-home.component.scss']
})
export class ValidatorHomeComponent implements OnInit, OnDestroy {
  @Input() user: User;
  pendingFilesWithTotal: FileAssociation = undefined;
  destroy$ = new Subject<boolean>();
  totalRecordCount = 0;
  pendingFiles$ = this.partNumberRevisionSandboxService.pendingFiles$;
  submitValidationActionSuccess$ = this.partNumberRevisionSandboxService.submitValidationActionSuccess$;
  showPendingFilesLoader = true;
  FileReviewSectionType = FileReviewSectionType;
  ActionType = ActionType;

  constructor(
      private partNumberRevisionSandboxService: PartNumberRevisionSandboxService
    ) { }

  ngOnInit(): void {
   const paginationRequest = <PaginationRequest>{
   length: 10,
   pageIndex: 0,
   pageSize: 1,
   previousPageIndex: -1
  };

    this.partNumberRevisionSandboxService.getPendingFiles(paginationRequest);

    this.pendingFiles$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileAssociationDetails: FileAssociation) => {
        this.showPendingFilesLoader = false;
        this.pendingFilesWithTotal = fileAssociationDetails;
      });

    this.submitValidationActionSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: any) => {
        this.showPendingFilesLoader = false;
        if (status) {
          const pReq = <PaginationRequest>{
            length: 10,
            pageIndex: 0,
            pageSize: 1,
            previousPageIndex: -1
           };
          this.partNumberRevisionSandboxService.getPendingFiles(pReq);
        }
    });
  }

  ngOnDestroy() {
    this.partNumberRevisionSandboxService.clearPendingFilesData();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  onPendingFilesPagingTraversal(paginationRequest: PaginationRequest){
    this.partNumberRevisionSandboxService.getPendingFiles(paginationRequest);
  }

}

import { Action, createReducer, on } from '@ngrx/store';

import * as PartNumberRevisionActions from '../actions/part-number-revision.actions';
import { PartNumberRevision } from '@shared-models/part-number-revision';
import { FileAssociation } from '@shared-models/file-association';
import { ApiError } from '@shared-models/api-error';

export interface State {
    partNumberRevisions: PartNumberRevision[];
    pendingFiles: FileAssociation;
    filesToValidate: FileAssociation;
    filesOriginatedPendingValidation: FileAssociation;
    originatorFiles: FileAssociation;
    originatorPastFiles: FileAssociation;
    replaceAndAddFileAssociationSuccess: boolean;
    submitValidationSuccess: boolean;
    submitValidationFailure: ApiError;
    addFileAssociationSuccess: boolean;
    obsoleteFileAssociationSuccess: boolean;
}

export const initialState: State = {
    partNumberRevisions: undefined,
    pendingFiles: undefined,
    filesToValidate: undefined,
    filesOriginatedPendingValidation: undefined,
    originatorFiles: undefined,
    originatorPastFiles: undefined,
    replaceAndAddFileAssociationSuccess: undefined,
    submitValidationSuccess: undefined,
    submitValidationFailure: undefined,
    addFileAssociationSuccess: undefined,
    obsoleteFileAssociationSuccess: undefined
};

const partNumberRevisionReducer = createReducer(
    initialState,
    on(PartNumberRevisionActions.GetPartNumberRevisions, (state, action) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.GetPartNumberRevisionsSuccess, (state, action) => ({
        ...state,
        partNumberRevisions: action.payload
    })),
    on(PartNumberRevisionActions.GetPartNumberRevisionsFailure, (state, action) => ({
        ...state,
        partNumberRevisions: null
    })),
    on(PartNumberRevisionActions.ClearPartNumberRevisionsData, (state, action) => ({
        ...state,
        partNumberRevisions: undefined
    })),
    on(PartNumberRevisionActions.GetPendingFiles, (state, action) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.GetPendingFilesSuccess, (state, action) => ({
        ...state,
        pendingFiles: action.payload
    })),
    on(PartNumberRevisionActions.GetPendingFilesFailure, (state, action) => ({
        ...state,
        pendingFiles: null
    })),
    on(PartNumberRevisionActions.ClearPendingFilesData, (state, action) => ({
        ...state,
        pendingFiles: undefined
    })),
    on(PartNumberRevisionActions.AddFileAssociation, (state) => ({
        ...state
    })),
    on(PartNumberRevisionActions.AddFileAssociationSuccess, (state, action) => ({
        ...state,
        addFileAssociationSuccess: true
    })),
    on(PartNumberRevisionActions.AddFileAssociationFailure, (state, action) => ({
        ...state,
        addFileAssociationSuccess: false
    })),
    on(PartNumberRevisionActions.ClearAddFileAssociationSuccessData, (state, action) => ({
        ...state,
        addFileAssociationSuccess: undefined
    })),
    on(PartNumberRevisionActions.ReplaceAndAddFileAssociation, (state) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.ReplaceAndAddFileAssociationSuccess, (state, action) => ({
        ...state,
        replaceAndAddFileAssociationSuccess: true
    })),
    on(PartNumberRevisionActions.ReplaceAndAddFileAssociationFailure, (state, action) => ({
        ...state,
        replaceAndAddFileAssociationSuccess: false
    })),
    on(PartNumberRevisionActions.GetFilesToValidate, (state, action) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.GetFilesToValidateSuccess, (state, action) => ({
        ...state,
        filesToValidate: action.payload
    })),
    on(PartNumberRevisionActions.GetFilesToValidateFailure, (state, action) => ({
        ...state,
        filesToValidate: null
    })),
    on(PartNumberRevisionActions.ClearFilesToValidateData, (state, action) => ({
        ...state,
        filesToValidate: undefined
    })),
    on(PartNumberRevisionActions.GetFilesOriginatedPendingValidation, (state, action) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.GetFilesOriginatedPendingValidationSuccess, (state, action) => ({
        ...state,
        filesOriginatedPendingValidation: action.payload
    })),
    on(PartNumberRevisionActions.GetFilesOriginatedPendingValidationFailure, (state, action) => ({
        ...state,
        filesOriginatedPendingValidation: null
    })),
    on(PartNumberRevisionActions.ClearFilesOriginatedPendingValidationData, (state, action) => ({
        ...state,
        filesOriginatedPendingValidation: undefined
    })),
    on(PartNumberRevisionActions.GetOriginatorActiveFiles, (state, action) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.GetOriginatorActiveFilesSuccess, (state, action) => ({
        ...state,
        originatorFiles: action.payload
    })),
    on(PartNumberRevisionActions.GetOriginatorActiveFilesFailure, (state, action) => ({
        ...state,
        originatorFiles: null
    })),
    on(PartNumberRevisionActions.GetOriginatorPastFiles, (state, action) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.GetOriginatorPastFilesSuccess, (state, action) => ({
        ...state,
        originatorPastFiles: action.payload
    })),
    on(PartNumberRevisionActions.GetOriginatorPastFilesFailure, (state, action) => ({
        ...state,
        originatorPastFiles: null
    })),
    on(PartNumberRevisionActions.ClearOriginatorFilesData, (state, action) => ({
        ...state,
        originatorFiles: undefined
    })),
    on(PartNumberRevisionActions.SubmitValidationAction, (state, action) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.SubmitValidationActionSuccess, (state, action) => ({
        ...state,
        submitValidationSuccess: action.payload
    })),
    on(PartNumberRevisionActions.SubmitValidationActionFailure, (state, action) => ({
        ...state,
        submitValidationFailure: action.payload
    })),
    on(PartNumberRevisionActions.ClearReplaceAndAddFileAssociationSuccessData, (state, action) => ({
        ...state,
        replaceAndAddFileAssociationSuccess: undefined
    })),
    on(PartNumberRevisionActions.ObsoleteFileAssociation, (state, action) => ({
        ...state,
    })),
    on(PartNumberRevisionActions.ObsoleteFileAssociationnSuccess, (state, action) => ({
        ...state,
        obsoleteFileAssociationSuccess: true
    })),
    on(PartNumberRevisionActions.ReplaceAndAddFileAssociationFailure, (state, action) => ({
        ...state,
        obsoleteFileAssociationSuccess: false
    })),
    on(PartNumberRevisionActions.ClearObsoleteFileAssociationSuccessData, (state, action) => ({
        ...state,
        obsoleteFileAssociationSuccess: undefined
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return partNumberRevisionReducer(state, action);
}

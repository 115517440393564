import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PartNumberRevisionSandboxService } from '@shared-services/part-number-revision-sandbox.service';
import { PartNumberRevision } from '@shared-models/part-number-revision';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-originator-home',
  templateUrl: './originator-home.component.html',
  styleUrls: ['./originator-home.component.scss']
})
export class OriginatorHomeComponent implements OnInit, OnDestroy {
  @ViewChild('partNumberInput') partNumberInput: ElementRef;
  destroy$ = new Subject<boolean>();
  partNumberRevision$ = this.partNumberRevisionSandboxService.partNumberRevision$;
  retrievedPartNumberRevisions = [];
  showNotFoundMessage = false;
  disableSearchButton = true;
  showLoader = false;
  faTimes = faTimes;

  partNumberFormGroup = new FormGroup({
    partNumberInput: new FormControl('', [Validators.required])
  });

  constructor(
    public dialog: MatDialog,
    private partNumberRevisionSandboxService: PartNumberRevisionSandboxService,
    private router: Router
  ) { }

  ngOnInit() {
    this.partNumberFormGroup.get('partNumberInput').valueChanges.subscribe(value => {
      if (this.partNumberFormGroup.get('partNumberInput').value !== null &&
        this.partNumberFormGroup.get('partNumberInput').value !== '') {
          this.disableSearchButton = false;
      } else {
        this.disableSearchButton = true;
        this.showNotFoundMessage = false;
      }
    });

    this.partNumberRevision$
      .pipe(takeUntil(this.destroy$))
      .subscribe((partNumberRevisions: PartNumberRevision[]) => {
        this.showLoader = false;
        if (partNumberRevisions?.length > 0) {
          this.retrievedPartNumberRevisions = partNumberRevisions;
        } else if (partNumberRevisions !== undefined) {
            this.showNotFoundMessage = true;
            this.disableSearchButton = false;
        }
      });
  }

  partNumberSearch(partNumber: string) {
    this.showLoader = true;
    this.resetState();
    this.partNumberRevisionSandboxService.getPartNumberRevisions(partNumber);
  }

  clearPartNumberInput() {
    this.resetState();
    this.partNumberFormGroup.get('partNumberInput').reset();
  }

  resetState() {
    this.retrievedPartNumberRevisions = [];
    this.disableSearchButton = true;
    this.showNotFoundMessage = false;
    this.partNumberRevisionSandboxService.clearPartNumberRevisionsData();
  }

  goToPartNumberLandingPage(partNumberRevision: PartNumberRevision) {
    window.localStorage.setItem('selectedPartNumberRevision', JSON.stringify(partNumberRevision));

    const route = `/part-number-landing-page`;
    this.router.navigate([route], { state: { partNumberDetails: partNumberRevision } });
  }

  ngOnDestroy() {
    this.partNumberRevisionSandboxService.clearPartNumberRevisionsData();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { SessionStateType } from '../models/session-state-type';

@Injectable({ providedIn: 'root' })
export class SessionStateService {

  getItem(key: SessionStateType): string {
    return sessionStorage.getItem(key);
  }

  setItem(key: SessionStateType, value: string): void {
    sessionStorage.setItem(key, value);
  }

  removeItem(key: SessionStateType): void {
    sessionStorage.removeItem(key);
  }
}

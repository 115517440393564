import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() fileTypes: string[];
  @Input() disableRemoveFile: boolean;
  @Output() onUpload = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  maxFiles = 1;
  fileList: any[] = [];
  errorMessage: string;
  faMinusCircle = faMinusCircle;
  showDropArea = true;

  constructor() { }

  fileUploadHandler(files: FileList) {
    this.errorMessage = null;

    if (files.length > this.maxFiles) {
      this.errorMessage = `A maximum of ${this.maxFiles} file(s) can only be uploaded at this time.`;
      return null;
    }

    this.prepareFilesList(files);

  }

  removeFile(index: number) {
    if (!this.disableRemoveFile) {
      this.errorMessage = null;
      this.onDelete.emit(this.fileList[index]);
      this.fileList.splice(index, 1);

      if (this.fileList.length < 1) {
        this.showDropArea = true;
      }
    }
  }

  prepareFilesList(files: FileList) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < files.length; i++) {
        if (this.isFileTypeValid(files[i].name)) {
            this.onUpload.emit(files[i]);
            this.fileList.push({
              name: files[i].name,
              type: '',
              path: ''
            } as any);
        this.showDropArea = false;
        } else {
          this.errorMessage = 'Please select a valid file type (.bpm, .csv, .txt, .xml).';
        }
    }
  }

  isFileTypeValid(fileName: string): boolean {
    const fileExtension = fileName.split('.')[1];
    return this.fileTypes.includes(fileExtension.toLowerCase());
  }

  maxFilesLimitReached(): boolean {
    return (this.fileList.length) > (this.maxFiles ? this.maxFiles : 999);
  }

  resetFileInput(event: { target: HTMLInputElement }) {
    event.target.value = '';
    this.errorMessage = null;
  }
}

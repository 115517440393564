import { Action, createReducer, on } from '@ngrx/store';

import * as SharedActions from '@shared/store/actions/shared.actions';
import { ApiError } from '@shared-models/api-error';

export interface State {
    systemError: ApiError;
}

export const initialState = {
    systemError: undefined
};

const sharedReducer = createReducer(
    initialState,
    on(SharedActions.SetSystemError, (state, action) => ({
       ...state,
       systemError: action.payload,
    })),
    on(SharedActions.ClearSystemError, (state, action) => ({
        ...state,
        systemError: undefined,
     }))
 );

export function reducer(state: State | undefined, action: Action) {
    return sharedReducer(state, action);
}

import { Component } from '@angular/core';
import { UserRoleType } from '@shared-models/user-role-type';
import { UserSandboxService } from '@shared-services/user-sandbox.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  userInfo$ = this.userService.user$;
  UserRoleType = UserRoleType;
  userLoading$ = this.userService.userLoading$;

  constructor(
    public userService: UserSandboxService
  ) { }

}

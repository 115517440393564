import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { UserApiService } from '@shared-api-services/user-api.service';

import * as userActions from '../actions/user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userApiService: UserApiService) { }

    getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.GetUser),
      mergeMap(() =>
        this.userApiService.getUser().pipe(
          map((user) => userActions.GetUserSuccess({ payload: user })),
          catchError((error) => of(
            userActions.GetUserFailure({ payload: error })
            )),
          )
        )
      )
    );

    getValidators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.GetValidators),
      mergeMap(() =>
        this.userApiService.getValidators().pipe(
          map((user) => userActions.GetValidatorsSuccess({ payload: user })),
          catchError((error) => of(
            userActions.GetValidatorsFailure({ payload: error })
            ))
          )
        )
      )
    );

  }

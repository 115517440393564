import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FailureModalData } from '@shared-components/failure-modal/failure-modal-data';
import { FailureModalComponent } from '@shared-components/failure-modal/failure-modal.component';
import { SuccessModalData } from '@shared-components/success-modal/success-modal-data';
import { SuccessModalComponent } from '@shared-components/success-modal/success-modal.component';
import { EnvironmentConfig } from '@shared-models/environment-config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppSandboxService } from './app-sandbox.service';

@Injectable({ providedIn: 'root' })
export class SharedModalService {
  destroy$ = new Subject<boolean>();
  environmentConfig: EnvironmentConfig;

  constructor(
    public dialog: MatDialog,
    private appSandboxService: AppSandboxService,
  ) {
  }

  openSuccessModal(successModalData: SuccessModalData) {
    this.dialog.open(SuccessModalComponent, {
      autoFocus: false,
      panelClass: 'success-modal-dialog',
      data: {
        title: successModalData.title,
        keepModalOpen: successModalData.keepModalOpen
      } as SuccessModalData
    });
  }

  openFailureModal(failureModalData: FailureModalData) {
    this.dialog.open(FailureModalComponent, {
      autoFocus: false,
      panelClass: 'failure-modal-dialog',
      data: failureModalData
    }).afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.appSandboxService.clearSystemError();
      });

  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentConfig } from '../models/environment-config';


@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  constructor(
    private http: HttpClient
  ) { }

  getEnvironmentConfiguration(): Observable<EnvironmentConfig> {
    return this.http.get<EnvironmentConfig>('/assets/env-config.json');
  }
}

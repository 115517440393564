import * as fromPartNumberRevision from './reducers/part-number-revision.reducer';
import * as fromUser from './reducers/user.reducer';
import * as fromShared from './reducers/shared.reducer';

export interface State {
    partNumberRevision: fromPartNumberRevision.State;
    user: fromUser.State;
    shared: fromShared.State;
}

export const reducers = {
    partNumberRevision: fromPartNumberRevision.reducer,
    user: fromUser.reducer,
    shared: fromShared.reducer
};

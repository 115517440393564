import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@shared-models/environment-config';
import { EnvironmentService } from '@shared/infrastructure/environment.service';
import { loadModule } from '@shared-utility/load-scripts';

@Injectable()
export class WebComponentService {

    environmentConfig: EnvironmentConfig;
    constructor(
        environmentService: EnvironmentService
    ) {
        this.environmentConfig = environmentService.getEnvironmentConfig();
    }

    loadMiLoaderComponent() {
        const miLoaderEsmUrl = `${this.environmentConfig.illuminaWebComponentsUrl}/ilmn-loader/resources/ilmn-loader.esm.js`;
        const miLoaderJsUrl = `${this.environmentConfig.illuminaWebComponentsUrl}/ilmn-loader/resources/ilmn-loader.js`;
        loadModule(miLoaderEsmUrl, miLoaderJsUrl);
    }

}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as store from '@shared-store/app.store';
import * as SharedActions from '@shared-store/actions/shared.actions';
import * as SharedSelectors from '@shared-store/selectors/shared.selectors';

@Injectable({
  providedIn: 'root'
})

export class AppSandboxService {
  selectSystemError$ = this.appStore.select(SharedSelectors.selectSystemError);

  constructor(
    private appStore: Store<store.State>,
  ) {
  }

  clearSystemError() {
    this.appStore.dispatch(SharedActions.ClearSystemError());
  }

}

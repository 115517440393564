import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UploadUrlInfo } from '../models/upload-url-info';
import { CompleteUploadInfo } from '../models/complete-upload-info';
import { map } from 'rxjs/operators';
import { FileUploadDto } from '@shared-models/file-upload.dto';
import { SupportedFileType } from '@shared-models/supported-file-type';

@Injectable()
export class UploadService {
    constructor(
        private http: HttpClient
    ) { }

    getUploadURL(fileData, headers): Observable<UploadUrlInfo> {
        return this.http.post<{ data: UploadUrlInfo }>('/productfile/v1/productfile/assets/upload?type=startupload', fileData, { headers }).pipe(map(res => res.data));
    }

    getMultipartURL(fileInfo): Promise<{ data: string }> {
        return this.http.post<{ data: string }>('/productfile/v1/productfile/assets/upload?type=uploadurl', fileInfo).toPromise();
    }

    uploadBlob(url: string, blob: string, fileUploadDto: FileUploadDto) {
        const derivedContentType = this.getContentType(fileUploadDto);
        const headers = new HttpHeaders().set('Content-Type', derivedContentType);
        const request = new HttpRequest('PUT', url, blob, { headers, reportProgress: true });
        return this.http.request(request);
    }

    completeUpload(uploadData): Observable<CompleteUploadInfo> {
        return this.http.post<{ data: CompleteUploadInfo }>('/productfile/v1/productfile/assets/upload?type=completeupload', uploadData).pipe(map(res => res.data));
    }

    private getContentType(fileUploadDto: FileUploadDto) {
        const fileExtension = fileUploadDto.fileMetaData.fileName.split('.').pop().toLowerCase().trim();
        let contentType = fileUploadDto.file.type;
        if (fileExtension === SupportedFileType.Bpm) {
            contentType = 'application/octet-stream';
        } else if (fileExtension === SupportedFileType.Xml) {
            contentType = 'application/xml';
        }
        return contentType;
    }
}

import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FileReviewSectionType } from '@shared-models/file-review-section-type';
import { ActionType } from '@shared-models/action-type';
import { FileAssociation, FileAssociationDetails } from '@shared-models/file-association';
import { MatDialog } from '@angular/material/dialog';
import { FileSelectionComponent } from '@shared-components/file-selection/file-selection.component';
import { PartNumberRevision } from '@shared-models/part-number-revision';
import { ActionDialogComponent } from '@shared-components/action-dialog/action-dialog.component';
import { User } from '@shared-models/user';
import { WorkflowType } from '@shared-models/workflow-type';
import { FileSelectionData } from '@shared-components/file-selection/file-selection-data';
import { ActionDialogData } from '@shared-components/action-dialog/action-dialog-data';
import { WorkflowDetails, WorkflowRequest } from '@shared-models/workflow-request';
import { PartNumberRevisionSandboxService } from '@shared-services/part-number-revision-sandbox.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { PaginationRequest } from '@shared-models/pagination-request';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-file-review-section',
  templateUrl: './file-review-section.html',
  styleUrls: ['./file-review-section.scss']
})
export class FileReviewSectionComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() section: FileReviewSectionType;
  @Input() pendingFiles: FileAssociation;
  @Input() filesToValidate: FileAssociation;
  @Input() filesOriginatedPendingValidation: FileAssociation;
  @Input() activeAssociatedFiles: FileAssociation;
  @Input() pastAssociatedFiles: FileAssociation;
  @Input() showLoader = true;
  @Input() partNumberRevision: PartNumberRevision;
  @Input() user: User;
  @Input() validators: User[];
  @Output() onSubmitValidationActionSuccess = new EventEmitter();
  @Output() onObsoleteFileAssociationSuccess = new EventEmitter();
  @Output() onPendingFilesPageTraversal = new EventEmitter<PaginationRequest>();
  @Output() onValidationPageTraversal = new EventEmitter<PaginationRequest>();
  @Output() onOriginatorPageTraversal = new EventEmitter<PaginationRequest>();
  @Output() onActiveAssociatedPageTraversal = new EventEmitter<PaginationRequest>();
  @Output() onPastAssociatedPageTraversal = new EventEmitter<PaginationRequest>();

  submitValidationActionSuccess$ = this.partNumberRevisionSandboxService.submitValidationActionSuccess$;
  obsoleteFileAssociationSuccess$ = this.partNumberRevisionSandboxService.obsoleteFileAssociationSuccess$;
  destroy$ = new Subject<boolean>();

  fileFormGroup: FormGroup;
  disableActionButton = true;
  FileReviewSectionType = FileReviewSectionType;
  ActionType = ActionType;
  pageSize = 5;
  pageIndex = 0;
  validatedFiles = false;
  selection: SelectionModel<Element> = new SelectionModel<Element>(true, []);

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private partNumberRevisionSandboxService: PartNumberRevisionSandboxService,
  ) { }

  ngOnInit(): void {
    this.fileFormGroup = this.formBuilder.group({
      validations: this.formBuilder.array([])
    });

    this.submitValidationActionSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: any) => {
        this.showLoader = false;
        if (status) {
          this.validatedFiles = true;
          this.resetFileFormGroup();
          this.onSubmitValidationActionSuccess.emit();
        }
    });

    this.obsoleteFileAssociationSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: any) => {
        this.showLoader = false;
        if (status) {
          this.validatedFiles = true;
          this.onObsoleteFileAssociationSuccess.emit();
        }
    });

  }

  ngOnChanges(changes: SimpleChanges) {

    if ((changes.filesToValidate?.currentValue || changes.pendingFiles?.currentValue) && this.validatedFiles) {
        // force refresh paginator count by going back to first page
        this.paginator?.firstPage();
        this.validatedFiles = false;
    }

    const validations = this.fileFormGroup?.get('validations') as FormArray;

    if (validations?.length === 0) {
      this.disableActionButton = true;
    } else {
      this.disableActionButton = false;
    }
  }

  ngAfterViewChecked(): void {
    const paginatorIntl = this.paginator?._intl;
    if (paginatorIntl?.nextPageLabel && paginatorIntl?.previousPageLabel) {
      paginatorIntl.nextPageLabel = '';
      paginatorIntl.previousPageLabel = '';
    }
  }

  onChangeFileFormGroup(event) {
    const validations = this.fileFormGroup.get('validations') as FormArray;
    const selectedFile = event.source.value;

    if (event.checked) {
      validations.push(new FormControl(selectedFile));
      this.selection.toggle(selectedFile.fileId);
    } else {
      const i = validations.controls.findIndex(x => x.value === selectedFile);
      validations.removeAt(i);
      this.selection.deselect(selectedFile.fileId);
    }

    if (validations.length === 0) {
      this.disableActionButton = true;
    } else {
      this.disableActionButton = false;
    }
  }

  onPendingFilePageChange(paginationParams: PaginationRequest) {
    this.onPendingFilesPageTraversal.emit(paginationParams);
  }

  onValidationPageChange(paginationParams: PaginationRequest) {
    this.onValidationPageTraversal.emit(paginationParams);
  }

  onOriginatorPageChange(paginationParams: PaginationRequest) {
    this.onOriginatorPageTraversal.emit(paginationParams);
  }

  onactiveAssociatedFilesPageChange(paginationParams: PaginationRequest) {
    this.onActiveAssociatedPageTraversal.emit(paginationParams);
  }

  onPastAssociatedFilesPageChange(paginationParams: PaginationRequest) {
    this.onPastAssociatedPageTraversal.emit(paginationParams);
  }

  openFileSelectionForReplace(fileAssociationDetails: FileAssociationDetails) {
    const fileSelectionDialog = this.dialog.open(FileSelectionComponent, {
      panelClass: 'file-selection',
      disableClose: true,
      data: {
        partNumberRevision: this.partNumberRevision,
        user: this.user,
        validators: this.validators,
        fileAssociationDetail: fileAssociationDetails,
        filesToValidate: this.filesToValidate
      } as unknown as FileSelectionData
     });

     fileSelectionDialog?.componentInstance.onReplaceAndAddFileAssociationComplete
      .subscribe(() => {
          fileSelectionDialog.close();
      });
  }

  openActionDialogForObsolete(fileToBeObsoleted: FileAssociationDetails) {
    const actionDialog = this.dialog.open(ActionDialogComponent, {
      panelClass: 'action-dialog',
      disableClose: true,
      data: {
        title: 'Obsolete Associated File',
        actionFileName: fileToBeObsoleted.fileName,
        workflowType: WorkflowType.Obsolete,
        validators: this.validators,
        filesToValidate: this.filesToValidate?.data?.fileList
      } as ActionDialogData
    });

    actionDialog?.componentInstance.onSubmitAction
      .subscribe((selectedValidatorEmail: string) => {
        this.submitObsoleteFile(selectedValidatorEmail, fileToBeObsoleted);
        actionDialog.close();
        this.partNumberRevisionSandboxService.clearObsoleteFileAssociationSuccessData();
      });
  }

  openActionDialogForReject() {
    // Get list of selected files from formGroup
    const actionDialog = this.dialog.open(ActionDialogComponent, {
      panelClass: 'action-dialog',
      disableClose: true,
      data: {
        title: 'Reject Associated File',
        actionType: ActionType.Rejected,
        validators: this.validators
      } as ActionDialogData
    });

    actionDialog?.componentInstance.onSubmitAction
      .subscribe((commentsText: string) => {
        this.submitValidationAction(ActionType.Rejected, commentsText);
        actionDialog.close();
      });
  }

  submitFilesToValidateForApproval() {
    this.submitValidationAction(ActionType.Approved);
  }

  formatDate(date: string) {
    const theDate = new Date(date);
    return date && date !== '' ?  `${theDate.toLocaleDateString()} ${theDate.toLocaleTimeString()}` : '';
  }

  private submitObsoleteFile(selectedValidatorEmail: string, fileToBeObsoleted: FileAssociationDetails) {
    const userWhoInitiatedObsoleteAction = this.user.email;
    const workflowDetails: WorkflowDetails[] = [];
    const workflowDetail = {
      fileId: fileToBeObsoleted.fileId,
      workflowHeaderId: fileToBeObsoleted.workflowHeaderId,
      partNumber: fileToBeObsoleted.partNumber,
      fileName: fileToBeObsoleted.fileName,
      validator: selectedValidatorEmail,
      originator: userWhoInitiatedObsoleteAction
    } as WorkflowDetails;

    workflowDetails.push(workflowDetail);

    const workflowRequest = {
      actionType: ActionType.Inactivated,
      workflowType: WorkflowType.Obsolete,
      workflowDetails,
      comments: ''
    } as WorkflowRequest;

    this.showLoader = true;
    this.partNumberRevisionSandboxService.obsoleteFileAssociation(workflowRequest);
  }

  private submitValidationAction(actionType: ActionType, comments?: string) {

    // Get list of selected files from formGroup
    const selectedFilesForAction = this.fileFormGroup.get('validations').value;

    const addWorkflowDetails: WorkflowDetails[] = [];
    const obsoleteWorkflowDetails: WorkflowDetails[] = [];
    const replaceWorkflowDetails: WorkflowDetails[] = [];

    selectedFilesForAction.forEach((file) => {
      if (file.action === WorkflowType.Add) {
        addWorkflowDetails.push(this.constructWorkflowDetails(file));

      } else if (file.action === WorkflowType.Obsolete) {
        obsoleteWorkflowDetails.push(this.constructWorkflowDetails(file));

      } else if (file.action === WorkflowType.Replace) {
        replaceWorkflowDetails.push(this.constructWorkflowDetails(file));
      }
    });

    // Check each workflow detail type and call submit validation action with its own request
    if (addWorkflowDetails.length > 0) {
      const addWorkflowRequest = {
        actionType,
        workflowType: WorkflowType.Update,
        comments: comments ? comments : '',
        workflowDetails: addWorkflowDetails
      } as WorkflowRequest;

      this.showLoader = true;
      this.partNumberRevisionSandboxService.submitValidationAction(addWorkflowRequest);
    }

    if (obsoleteWorkflowDetails.length > 0) {
      const obsoleteWorkflowRequest = {
        actionType,
        workflowType: WorkflowType.Obsolete,
        comments: comments ? comments : '',
        workflowDetails: obsoleteWorkflowDetails
      } as WorkflowRequest;

      this.showLoader = true;
      this.partNumberRevisionSandboxService.submitValidationAction(obsoleteWorkflowRequest);
    }

    if (replaceWorkflowDetails.length > 0) {
      const replaceWorkflowRequest = {
        actionType,
        workflowType: WorkflowType.Replace,
        comments: comments ? comments : '',
        workflowDetails: replaceWorkflowDetails
      } as WorkflowRequest;

      this.showLoader = true;
      this.partNumberRevisionSandboxService.submitValidationAction(replaceWorkflowRequest);
    }

  }

  constructWorkflowDetails(file) {
   return {
      fileId: file.fileId,
      workflowHeaderId: file.workflowHeaderId,
      partNumber: file.partNumber,
      fileName: file.fileName,
      validator: this.user.email,
      originator: file.originator,
      replacedFileId: file.replacedFileId,
      createdBy: file.createdBy,
      replacedFileCreatedBy: file.replacedFileCreatedBy
    } as WorkflowDetails;

  }

  private resetFileFormGroup() {
    this.fileFormGroup = this.formBuilder.group({
      validations: this.formBuilder.array([])
    });
  }

}

import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '../models/environment-config';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private environmentConfig: EnvironmentConfig = <EnvironmentConfig>{};

  constructor() { }

  registerEnvironmentConfig(config: EnvironmentConfig) {
    Object.assign(this.environmentConfig, config);
  }

  getEnvironmentConfig(): EnvironmentConfig {
    return this.environmentConfig;
  }
}

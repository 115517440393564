<div class="part-number-landing-page__container">
    <div class="part-number-landing-page__back">
        <a [routerLink]="'/pages/home'">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
            <span> Back</span>
        </a>
    </div>
    <div class="part-number-landing-page__header">
        <h2>Part Number Landing Page</h2>
    </div>
    <!-- Part Number Details-->
    <app-part-number-details [partNumberRevision]="partNumberRevision" [user]="user" [validators]="validators" [filesToValidate]="filesToValidateWithTotal?.data?.fileList"></app-part-number-details>

    <!-- Files To Validate (validator role only)-->
    <app-file-review-section *ngIf="isValidator" [section]="FileReviewSectionType.FilesToValidate" 
                                [filesToValidate]="filesToValidateWithTotal" 
                                [showLoader]="showFilesToValidateLoader"
                                [user]="user"
                                (onSubmitValidationActionSuccess)="refreshAllFileReviewSections()"
                                (onValidationPageTraversal)="onValidationPagingTraversal($event)"></app-file-review-section>

    <!-- Files Originated Pending Validations -->
    <app-file-review-section [section]="FileReviewSectionType.FilesOriginatedPendingValidation" 
                                [filesOriginatedPendingValidation]="filesOriginatedPendingValidationWithTotal" 
                                [showLoader]="showFilesOriginatedPendingValidationLoader"
                                [user]="user"
                                (onOriginatorPageTraversal)="onOriginatedPagingTraversal($event)"></app-file-review-section>

    <!-- Active Associated Files -->
    <app-file-review-section [section]="FileReviewSectionType.ActiveAssociatedFiles" 
                                [activeAssociatedFiles]="activeAssociatedFlilesWithTotal"
                                [filesToValidate]="filesToValidateWithTotal" 
                                [showLoader]="showOriginatorFilesLoader"
                                [partNumberRevision]="partNumberRevision"
                                [user]="user"
                                [validators]="validators"
                                (onObsoleteFileAssociationSuccess)="refreshAllFileReviewSections()"
                                (onActiveAssociatedPageTraversal)="onActiveAssociatedFilesTraversal($event)"></app-file-review-section>

    <!-- Past Associated Files -->
    <app-file-review-section [section]="FileReviewSectionType.PastAssociatedFiles" 
                                [pastAssociatedFiles]="pastAssociatedFilesWithTotal" 
                                [showLoader]="showOriginatorFilesLoader"
                                (onPastAssociatedPageTraversal)="onPastAssociatedFilesTraversal($event)"></app-file-review-section>
</div>
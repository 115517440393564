
 <mat-card appearance="outlined">
  <ilmn-loader variant="spinner-medium" [active]="showLoader"></ilmn-loader>
  <div class="originator__container">
    <h2 class="originator__container-title type__h3--alt">Originator</h2>
    <div class="originator__content">
      <div>
        <div class="originator__content-description">Search a specific part number by entering it in the search box below and clicking "Search".</div>
        <div class="originator__content-search">
          <mat-form-field class="originator__content-search-input" appearance="fill">
            <mat-label class="originator__content-search-input-label">Part Number</mat-label>
            <form [formGroup]="partNumberFormGroup">
              <input #partNumberInput matInput type="text" formControlName="partNumberInput">
            </form>
            <button *ngIf="partNumberInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearPartNumberInput()">
              <fa-icon class="originator__content-search-clear-icon" [icon]="faTimes"></fa-icon>
            </button>
          </mat-form-field>
          <button class="originator__content-search-button" mat-flat-button color="primary" [disabled]="disableSearchButton" (click)="partNumberSearch(partNumberInput.value)">Search</button>
          <span class="originator__content-not-found-msg" *ngIf="showNotFoundMessage">The part number you requested does not exist or was obsoleted.</span>
        </div>
      </div>
      <div *ngIf="retrievedPartNumberRevisions?.length > 0" class="originator__content-search-results">
        <div class="originator__content-search-results-header">
          <span class="originator__content-search-results pnumber">Part Number</span>
          <span class="originator__content-search-results pname">Part Name</span>
          <span class="originator__content-search-results description">Description</span>
          <span class="originator__content-search-results revision">Product Revision</span>
        </div>
        <div *ngFor="let rpnr of retrievedPartNumberRevisions" class="originator__content-search-results-row">
          <span class="originator__content-search-results pnumber">
            <a (click)="goToPartNumberLandingPage(rpnr)">{{ rpnr.partNumber }} </a>
          </span>
          <span class="originator__content-search-results pname">{{ rpnr.partName }}</span>
          <span class="originator__content-search-results description">{{ rpnr.productDescription }}</span>
          <span class="originator__content-search-results revision">{{ rpnr.productRevision }}</span>
        </div>
      </div>
    </div>
  </div>
 </mat-card>

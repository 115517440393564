import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@shared-models/environment-config';
import { EnvironmentService } from '@shared/infrastructure/environment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserApiService {
    environmentConfig: EnvironmentConfig;

    constructor(
        private http: HttpClient,
        environmentService: EnvironmentService
    ) {
        this.environmentConfig = environmentService.getEnvironmentConfig();
    }

    getUser(): Observable<any> {
        const url = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/user`;

        return this.http.get(url).pipe(
            map((response: any) => response.data)
        );
    }

    getValidators(): Observable<any> {
        const url = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/validators`;

        return this.http.get(url).pipe(
            map((response: any) => response.data)
        );
    }
}

<div class="file-selection__container">
	<div class="file-selection__header">
		<fa-icon class="file-selection__close-icon" 
			[icon]="faTimes" 
			(click)="closeDialog()">
		</fa-icon>
		<h2 class="type__h3--alt">File Selection</h2>
		
	</div>
	<div class="file-selection__body">
		<div class="file-selection__body-message">Select a file to associate to the selected part revision.</div>
		<div class="file-selection__body-part-info-container">
			<div class="file-selection__body-part-info-container-content">
				<div class="file-selection__body-part-info-container-content-label">PART NUMBER</div>
				<div class="file-selection__body-part-info-container-content-label-value">{{ data.partNumberRevision.partNumber }}</div>
			</div>
			<div class="file-selection__body-part-info-container-content">
				<div class="file-selection__body-part-info-container-content-label">PART NAME</div>
				<div class="file-selection__body-part-info-container-content-label-value">{{ data.partNumberRevision.partName }}</div>
			</div>
			<div class="file-selection__body-part-info-container-content">
				<div class="file-selection__body-part-info-container-content-label">DESCRIPTION</div>
				<div class="file-selection__body-part-info-container-content-label-value">{{ data.partNumberRevision.productDescription }}</div>
			</div>

			<div class="file-selection__body-part-info-container-content-revision">
				<div class="file-selection__body-part-info-container-content-label">PRODUCT REVISION</div>
				<div class="file-selection__body-part-info-container-content-label-value-revision">{{ data.partNumberRevision.productRevision }}</div>
			</div>
		</div>
		  
		<div class="file-selection__body-file-upload-container">
			<div *ngIf="data.fileAssociationDetail?.fileName && data.fileAssociationDetail?.fileName !== ''" class="file-selection__body-part-info-container-content-file-to-be-replaced">
				<span class="file-selection__body-part-info-container-content-label">FILE TO BE REPLACED: </span>
				<span class="file-selection__body-part-info-container-content-label-value">{{ data.fileAssociationDetail.fileName }}</span>
			</div>
			<app-file-upload [fileTypes]="acceptedFileTypes" [disableRemoveFile]="uploadInProgress && !uploadAndAssociationComplete" (onUpload)="prepareFile($event)"
				(onDelete)="clearUpload($event)">
			</app-file-upload>
			<div *ngIf="message !== ''" [ngClass]="hasError ? 'file-selection__body-message-complete error' : 'file-selection__body-message-complete'">
				{{ message }}
			</div>
			<div *ngIf="fileUpload && (fileUpload.status === FileUploadStatusType.Started || fileUpload.status === FileUploadStatusType.InProgress ) && !uploadAndAssociationComplete" class="file-selection__progress-bar" >
				<span *ngIf="fileUpload.status === FileUploadStatusType.Started">Starting File Upload and Association...</span>
				<span *ngIf="fileUpload.status === FileUploadStatusType.InProgress">File Upload and Association is {{ fileUpload.progress }}% complete...</span>
				<mat-progress-bar class="progress-bar-title" mode="determinate" [value]="fileUpload.progress"></mat-progress-bar>
			</div>
		</div>
	</div>

	<div class="file-selection__footer">
		<button [disabled]="disableAssociationButton" mat-flat-button color="primary" (click)="addFileAssociation()">Add New Association</button>
		<div class="file-selection__body-file-upload-container-validators">
			<form [formGroup]="validatorFormGroup">
				<mat-form-field class="file-selection__validator-selection" floatLabel="always">
					<mat-label>Validators</mat-label>
					<mat-select (selectionChange)="onValidatorSelectChange()" #validatorSelection formControlName="validatorSelection">
						<mat-option *ngFor="let v of data.validators" [value]="v.email">
							{{ v.userName }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</form>
		</div>
	</div>
</div>
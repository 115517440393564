import { Injectable } from '@angular/core';

import { EnvironmentService } from '../infrastructure/environment.service';
import { EnvironmentConfig } from '../models/environment-config';
import { of, Observable } from 'rxjs';
import { WindowRefService } from './window-ref.service';
import { HttpClient } from '@angular/common/http';
import { TokenResponse } from '../models/token-response';
import { map, share, catchError } from 'rxjs/operators';
import { SessionStateType } from '../models/session-state-type';
import { SessionStateService } from './session-state.service';
import { User } from '@shared-models/user';
import { UserSandboxService } from './user-sandbox.service';
import { Router } from '@angular/router';

export const accessToken = 'access_token';
const basePath = '/adminauth/v1';

@Injectable({ providedIn: 'root' })
export class AuthService {
  environmentConfig: EnvironmentConfig;
  window: Window;
  user: User;
  user$ = this.userSandboxService.user$;
  userError$ = this.userSandboxService.userError$;
  logoutInProgress = false;
  refreshTokensInProgress = false;

  constructor(
    private http: HttpClient,
    private sessionStateService: SessionStateService,
    windowRefService: WindowRefService,
    private router: Router,
    private userSandboxService: UserSandboxService,
    environmentService: EnvironmentService) {
      this.environmentConfig = environmentService.getEnvironmentConfig();
      this.window = windowRefService.nativeWindow;
    }

  isAuthenticated(): Observable<boolean> {
    if (this.sessionStateService.getItem(SessionStateType.AccessToken)) {
      return of(true);
    }
    const query = this.parseQueryString();

    // Check if the server returned an error string
    if (query.error) {
      return of(false);
    }

    // If the server returned an authorization code, attempt to exchange it for an access token
    if (query.code) {
      // Verify state matches what we set at the beginning
      if (this.sessionStateService.getItem(SessionStateType.PkceState) !== query.state) {
        this.sessionStateService.removeItem(SessionStateType.PkceState);
        this.sessionStateService.removeItem(SessionStateType.PkceCodeVerifier);
        return of(false);
      } else {
        const codeVerifier = this.sessionStateService.getItem(SessionStateType.PkceCodeVerifier);

        this.sessionStateService.removeItem(SessionStateType.PkceState);
        this.sessionStateService.removeItem(SessionStateType.PkceCodeVerifier);

        return this.getTokens(query.code, codeVerifier);
      }
    } else {
      return of(false);
    }
  }

  logout() {
    if (this.logoutInProgress) {
      return;
    }
    this.logoutInProgress = true;
    this.goToOkta();
  }

  goToOkta() {
    const state = this.generateRandomString();
    this.sessionStateService.setItem(SessionStateType.PkceState, state);

    const codeVerifier = this.generateRandomString();
    this.sessionStateService.setItem(SessionStateType.PkceCodeVerifier, codeVerifier);

    const data = this.encode(codeVerifier);
    const crypto = this.window.crypto || (<any>this.window).msCrypto;
    const hashed = crypto.subtle.digest('SHA-256', data);
    if (this.window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1) {
      if (hashed.result) {
        const codeChallenge = this.getCodeChallenge(hashed.result);
        const url = this.formatOktaUrl(state, codeChallenge);
        this.window.location.href = url;
      } else {
        this.goToOkta();
      }
    } else {
      hashed.then(digest => {
        const codeChallenge = this.getCodeChallenge(digest);
        const url = this.formatOktaUrl(state, codeChallenge);
        this.window.location.href = url;
      });
    }
  }

  formatOktaUrl(state: string, codeChallenge: string): string {
    return this.environmentConfig.authorizationUrl
    + '?response_type=code'
    + '&client_id=' + encodeURIComponent(this.environmentConfig.clientId)
    + '&state=' + encodeURIComponent(state)
    + '&scope=' + encodeURIComponent(this.environmentConfig.requestedScopes)
    + '&redirect_uri=' + encodeURIComponent(this.environmentConfig.appUrl)
    + '&code_challenge=' + encodeURIComponent(codeChallenge)
    + '&code_challenge_method=S256';
  }

  getTokens(authCode: string, codeVerifier): Observable<boolean> {
    const url = `${this.environmentConfig.tokenExchangeUrl}`;
    const params = {
      code: authCode,
      clientId: this.environmentConfig.clientId,
      redirectUri: this.environmentConfig.appUrl,
      codeVerifier
    };

    return this.http.post<{ data: TokenResponse }>(url, params)
    .pipe(
      map(res => res.data),
      map((tokenResponse: TokenResponse) => {
        this.sessionStateService.setItem(SessionStateType.AccessToken, tokenResponse.access_token);
        this.sessionStateService.setItem(SessionStateType.RefreshToken, tokenResponse.refresh_token);
        // refresh tokens a min before they expire.
        setTimeout(() => this.refreshTokens().subscribe(), parseInt(tokenResponse.expires_in, 10) * 980);
        return true;
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  refreshTokens(): Observable<boolean> {
    if (this.refreshTokensInProgress) {
      return of(false);
    }
    this.refreshTokensInProgress = true;
    const params = {
      clientId: this.environmentConfig.clientId,
      token: this.sessionStateService.getItem(SessionStateType.RefreshToken),
      source: 'productfiles'
    };
    return new Observable<boolean>(observer => {
      const url = `${this.environmentConfig.apiBaseUrl}${basePath}/auth?type=refresh`;

      this.http.post<{ data: TokenResponse }>(url, params).pipe(
        share(),
        map(res => res.data))
        .subscribe((tokenResponse: TokenResponse) => {
          this.sessionStateService.setItem(SessionStateType.AccessToken, tokenResponse.access_token);
          // refresh tokens a min before they expire.
          setTimeout(() => this.refreshTokens().subscribe(), parseInt(tokenResponse.expires_in, 10) * 980);
          this.refreshTokensInProgress = false;
          observer.next(true);
        },
        err => {
          this.refreshTokensInProgress = false;
          this.logout();
          observer.next(false);
        });
    });
  }

  private generateRandomString() {
    const array = new Uint32Array(28);
    const crypto = this.window.crypto || (<any>this.window).msCrypto;
    crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  private parseQueryString(): any {
    const query = this.window.location.search.substring(1);
    if (query === '') { return {}; }
    const segments = query.split('&').map(s => s.split('='));
    const queryString = {};
    segments.forEach(s => queryString[s[0]] = s[1]);
    return queryString;
  }

  private getCodeChallenge(digest) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(digest)))
      .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  private encode(codeVerifier: string) {
    const encoded = new Uint8Array(codeVerifier.length);
    for (let i = 0; i < codeVerifier.length; i += 1) {
      encoded[i] = codeVerifier.charCodeAt(i);
    }
    return encoded;
  }

  getUser(): Observable<User> {
    return new Observable<User>(observer => {
        if (this.user) {
            observer.next(this.user);
        } else {
            this.userSandboxService.getUser();
            this.user$
                .subscribe((user: User) => {
                    if (user) {
                        this.user = user;
                        observer.next(user);
                    }
                });
            this.userError$
                .subscribe((error) => {
                    if (error) {
                        this.deleteAccessToken();
                        if (error.status === 403) {
                            this.router.navigateByUrl('home');
                        } else {
                            window.location.reload();
                        }
                        observer.next(null);
                    }
                });
        }
    });
  }

  private deleteAccessToken() {
    this.sessionStateService.removeItem(SessionStateType.AccessToken);
  }
}

import { Component, OnInit } from '@angular/core';
import { UserSandboxService } from '@shared-services/user-sandbox.service';
import { WebComponentService } from '@shared-services/web-component.service.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ilmn-custom-product-files';
  user$ = this.userSandboxService.user$;
  destroy$ = new Subject<boolean>();

  constructor(
    private webComponentService: WebComponentService,
    private userSandboxService: UserSandboxService,
  ) {
    this.webComponentService.loadMiLoaderComponent();
    this.userSandboxService.getUser();
  }

  ngOnInit() {
    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
      });
    }
}

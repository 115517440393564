<div class="home__container">
    <ilmn-loader variant="spinner" [active]="userLoading$ | async"></ilmn-loader>
    <div class="home__welcome-msg">
        <h2 *ngIf="userInfo$ | async">Welcome, {{ (userInfo$ | async)?.userName }}!</h2>
    </div>

    <app-validator-home *ngIf="(userInfo$ | async)?.roles.includes(UserRoleType.Validator) && userInfo$ | async as userInfo" [user]="userInfo$ | async"></app-validator-home>
    <div class="home__container-originator">
        <app-originator-home *ngIf="(userInfo$ | async)?.roles.includes(UserRoleType.Originator)"></app-originator-home>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { EnvironmentConfig } from '../models/environment-config';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SessionStateType } from '../models/session-state-type';
import { SessionStateService } from '../services/session-state.service';
import { FailureModalData } from '@shared-components/failure-modal/failure-modal-data';
import { SharedModalService } from '@shared-services/shared-modal.service';

@Injectable({ providedIn: 'root' })
export class ProductFileHttpInterceptor implements HttpInterceptor {
  private environmentConfig: EnvironmentConfig;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private sessionStateService: SessionStateService,
    private sharedModalService: SharedModalService,
    environmentService: EnvironmentService
  ) {
    this.environmentConfig = environmentService.getEnvironmentConfig();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.modifyRequest(request)).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            return this.handle401Error(request, next);
          } else if (error.status === 500 || error.status === 502 || error.status === 504) {
            this.showFailureModal(error);
          }
          return throwError(error);
        }
      }));
  }

  modifyRequest(request: HttpRequest<any>): HttpRequest<any> {
    request = request.clone({
      url: this.updateUrl(request.url)
    });

    if (request.url.indexOf('adminauth') === -1 && !request.url.toLowerCase().startsWith('/assets')) {
      const accessToken = this.sessionStateService.getItem(SessionStateType.AccessToken);
      request = request.clone({
        setHeaders: {
          accessToken: accessToken ? accessToken : '',
          source: 'productfile',
        }
      });
    }

    return request;
  }

  private updateUrl(request: string) {
    if (request.toLowerCase().startsWith('http') || request.toLowerCase().startsWith('/assets')) {
      return request;
    } else {
      return this.environmentConfig.apiBaseUrl + request;
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshTokens().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return this.intercept(request, next);
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          return this.intercept(request, next);
        }));
    }
  }

  private showFailureModal(response) {
    // force loading of header map since its lazy-loaded
    response.headers.keys();

    const failureModalData = {
      title: 'An error occurred. Please contact technical support for additional assistance.',
      body: `Reference Id: ${ response.headers.get('x-amzn-requestid') }`
    } as FailureModalData;

    this.sharedModalService.openFailureModal(failureModalData);
  }
}

import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { User } from '@shared-models/user';
import { ActionType } from '@shared-models/action-type';
import { WorkflowType } from '@shared-models/workflow-type';
import { ActionDialogData } from './action-dialog-data';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionDialogComponent implements OnInit {
  @ViewChild('commentsInput') commentsInput: ElementRef;
  @ViewChild('validatorSelection') validatorSelection: MatSelect;
  @Output() onSubmitAction = new EventEmitter();
  faTimes = faTimes;
  WorkflowType = WorkflowType;
  ActionType = ActionType;
  validators: User[] = [];
  disableSubmitButton = true;
  submitButtonText = 'Submit';
  errorMessage = '';

  validatorFormGroup = new FormGroup({
    validatorSelection: new FormControl('', [Validators.required])
  });

  commentsFormGroup = new FormGroup({
    commentsInput: new FormControl('', [Validators.required])
  });


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ActionDialogData
  ) { }

  ngOnInit(): void {
    if (this.data.workflowType === WorkflowType.Obsolete) {
      this.submitButtonText =  'Make Obsolete';
    } else if (this.data.actionType === ActionType.Rejected) {
      this.submitButtonText = 'Reject';
    }

    this.commentsFormGroup.get('commentsInput').valueChanges.subscribe(value => {
      if (this.commentsFormGroup.get('commentsInput').value !== null &&
        this.commentsFormGroup.get('commentsInput').value !== '') {
          this.disableSubmitButton = false;
      } else {
        this.disableSubmitButton = true;
      }
    });
  }

  onValidatorSelectChange() {
      this.disableSubmitButton = false;
  }

  submit() {
    if (this.data.workflowType === WorkflowType.Obsolete) {
      if (this.isFileAlreadyQueued(this.data.actionFileName)) {
        this.errorMessage = 'The selected file is already queued for validation.';
        this.disableSubmitButton = true;
      } else {
        this.onSubmitAction.emit(this.validatorSelection.value);
      }
    }
    if (this.data.actionType === ActionType.Rejected) {
      this.onSubmitAction.emit(this.commentsFormGroup.get('commentsInput').value);
    }
  }

  clearCommentsInput() {
    this.resetState();
    this.commentsFormGroup.get('commentsInput').reset();
  }

  resetState() {
    this.disableSubmitButton = false;
  }

  isFileAlreadyQueued(filename: string) {
    return this.data.filesToValidate?.some(element => element.fileName === filename);
  }

}

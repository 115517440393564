import { State } from '@shared-store/app.store';

export function selectPartNumberRevisions(state: State) { return state.partNumberRevision.partNumberRevisions; }
export function selectPendingFiles(state: State) { return state.partNumberRevision.pendingFiles; }
export function selectFilesToValidate(state: State) { return state.partNumberRevision.filesToValidate; }
export function selectReplaceAndAddFileAssociationSuccess(state: State) { return state.partNumberRevision.replaceAndAddFileAssociationSuccess; }
export function selectFilesOriginatedPendingValidation(state: State) { return state.partNumberRevision.filesOriginatedPendingValidation; }
export function selectOriginatorFiles(state: State) { return state.partNumberRevision.originatorFiles; }
export function selectSubmitValidationSuccess(state: State) { return state.partNumberRevision.submitValidationSuccess; }
export function selectObsoleteFileAssociationSuccess(state: State) { return state.partNumberRevision.obsoleteFileAssociationSuccess; }
export function selectAddFileAssociationSuccess(state: State) { return state.partNumberRevision.addFileAssociationSuccess; }
export function selectOriginatorPastFiles(state: State) { return state.partNumberRevision.originatorPastFiles; }

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as store from '@shared-store/app.store';
import * as PartNumberRevisionActions from '@shared-store/actions/part-number-revision.actions';
import * as PartNumberRevisionSelectors from '@shared-store/selectors/part-number-revision.selectors';
import { WorkflowRequest } from '@shared-models/workflow-request';
import { FileUploadDto } from '@shared-models/file-upload.dto';
import { PartNumberRevision } from '@shared-models/part-number-revision';
import { PaginationRequest } from '@shared-models/pagination-request';

@Injectable()
export class PartNumberRevisionSandboxService {
    partNumberRevision$ = this.appStore.select(PartNumberRevisionSelectors.selectPartNumberRevisions);
    pendingFiles$ = this.appStore.select(PartNumberRevisionSelectors.selectPendingFiles);
    addFileAssociationSuccess$ = this.appStore.select(PartNumberRevisionSelectors.selectAddFileAssociationSuccess);
    filesToValidate$ = this.appStore.select(PartNumberRevisionSelectors.selectFilesToValidate);
    replaceAndAddFileAssociationSuccess$ = this.appStore.select(PartNumberRevisionSelectors.selectReplaceAndAddFileAssociationSuccess);
    filesOriginatedPendingValidation$ = this.appStore.select(PartNumberRevisionSelectors.selectFilesOriginatedPendingValidation);
    originatorFiles$ = this.appStore.select(PartNumberRevisionSelectors.selectOriginatorFiles);
    originatorPastFiles$ = this.appStore.select(PartNumberRevisionSelectors.selectOriginatorPastFiles);
    submitValidationActionSuccess$ = this.appStore.select(PartNumberRevisionSelectors.selectSubmitValidationSuccess);
    obsoleteFileAssociationSuccess$ = this.appStore.select(PartNumberRevisionSelectors.selectObsoleteFileAssociationSuccess);

    constructor(
        private appStore: Store<store.State>
    ) { }

    getPartNumberRevisions(partNumber: string) {
        this.appStore.dispatch(PartNumberRevisionActions.GetPartNumberRevisions({ payload: partNumber }));
    }

    getPendingFiles(paginationRequest: PaginationRequest) {
        this.appStore.dispatch(PartNumberRevisionActions.GetPendingFiles({ payload: paginationRequest }));
    }

    submitValidationAction(validations: WorkflowRequest) {
        this.appStore.dispatch(PartNumberRevisionActions.SubmitValidationAction({ payload: validations }));
    }

    obsoleteFileAssociation(validations: WorkflowRequest) {
        this.appStore.dispatch(PartNumberRevisionActions.ObsoleteFileAssociation({ payload: validations }));
    }

    getFilesToValidateSection(partNumberRevision: PartNumberRevision) {
        this.appStore.dispatch(PartNumberRevisionActions.GetFilesToValidate({ payload: partNumberRevision }));
    }

    getFilesOriginatedPendingValidationSection(partNumberRevision: PartNumberRevision) {
        this.appStore.dispatch(PartNumberRevisionActions.GetFilesOriginatedPendingValidation({ payload: partNumberRevision }));
    }

    getOriginatorActiveFilesSection(partNumberRevision: PartNumberRevision) {
        this.appStore.dispatch(PartNumberRevisionActions.GetOriginatorActiveFiles({ payload: partNumberRevision }));
    }

    getOriginatorPastFilesSection(partNumberRevision: PartNumberRevision) {
        this.appStore.dispatch(PartNumberRevisionActions.GetOriginatorPastFiles({ payload: partNumberRevision }));
    }

    clearPartNumberRevisionsData() {
        this.appStore.dispatch(PartNumberRevisionActions.ClearPartNumberRevisionsData());
    }

    clearPendingFilesData() {
        this.appStore.dispatch(PartNumberRevisionActions.ClearPendingFilesData());
    }

    clearFilesToValidate() {
        this.appStore.dispatch(PartNumberRevisionActions.ClearFilesToValidateData());
    }

    clearFilesOriginatedPendingValidationData() {
        this.appStore.dispatch(PartNumberRevisionActions.ClearFilesOriginatedPendingValidationData());
    }

    clearOriginatorFilesData() {
        this.appStore.dispatch(PartNumberRevisionActions.ClearOriginatorFilesData());
    }

    addFileAssociation(fileUploadDto: FileUploadDto) {
        this.appStore.dispatch(PartNumberRevisionActions.AddFileAssociation({ payload: fileUploadDto }));
    }

    replaceAndAddFileAssociation(fileUploadDto: FileUploadDto) {
        this.appStore.dispatch(PartNumberRevisionActions.ReplaceAndAddFileAssociation({ payload: fileUploadDto }));
    }

    clearReplaceAndAddFileAssociationSuccessData() {
        this.appStore.dispatch(PartNumberRevisionActions.ClearReplaceAndAddFileAssociationSuccessData());
    }

    clearAddFileAssociationSuccessData() {
        this.appStore.dispatch(PartNumberRevisionActions.ClearAddFileAssociationSuccessData());
    }

    clearObsoleteFileAssociationSuccessData() {
        this.appStore.dispatch(PartNumberRevisionActions.ClearObsoleteFileAssociationSuccessData());
    }
}

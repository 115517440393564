import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PartNumberRevisionApiService } from '@shared-api-services/part-number-revision.api.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as partNumberRevisionActions from '../actions/part-number-revision.actions';

@Injectable()
export class PartNumberRevisionEffects {
    constructor(
        private actions$: Actions,
        private partNumberRevisionApiService: PartNumberRevisionApiService
    ) { }

    getPartNumberRevisions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.GetPartNumberRevisions),
            mergeMap((action) =>
                this.partNumberRevisionApiService.getPartNumberRevisions(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.GetPartNumberRevisionsSuccess({ payload: results })),
                    catchError((error) => of(
                        partNumberRevisionActions.GetPartNumberRevisionsFailure({ payload: error }),
                    ))
                )
            )
        )
    );

    getPendingValidations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.GetPendingFiles),
            mergeMap((action) =>
                this.partNumberRevisionApiService.getPendingFiles(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.GetPendingFilesSuccess({ payload: results })),
                    catchError((error) => of(
                        partNumberRevisionActions.GetPendingFilesFailure({ payload: error }),
                    ))
                )
            )
        )
    );

    getFilesToValidate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.GetFilesToValidate),
            mergeMap((action) =>
                this.partNumberRevisionApiService.getFilesToValidate(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.GetFilesToValidateSuccess({ payload: results })),
                    catchError((error) => of(
                        partNumberRevisionActions.GetFilesToValidateFailure({ payload: error }),
                    ))
                )
            )
        )
    );

    getFilesOriginatedPendingValidation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.GetFilesOriginatedPendingValidation),
            mergeMap((action) =>
                this.partNumberRevisionApiService.getFilesOriginatedPendingValidation(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.GetFilesOriginatedPendingValidationSuccess({ payload: results })),
                    catchError((error) => of(
                        partNumberRevisionActions.GetFilesOriginatedPendingValidationFailure({ payload: error }),
                    ))
                )
            )
        )
    );

    getOriginatorActiveFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.GetOriginatorActiveFiles),
            mergeMap((action) =>
                this.partNumberRevisionApiService.getOriginatorFiles(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.GetOriginatorActiveFilesSuccess({ payload: results })),
                    catchError((error) => of(
                        partNumberRevisionActions.GetOriginatorActiveFilesFailure({ payload: error }),
                    ))
                )
            )
        )
    );

    getOriginatorPastFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.GetOriginatorPastFiles),
            mergeMap((action) =>
                this.partNumberRevisionApiService.getOriginatorPastFiles(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.GetOriginatorPastFilesSuccess({ payload: results })),
                    catchError((error) => of(
                        partNumberRevisionActions.GetOriginatorPastFilesFailure({ payload: error }),
                    ))
                )
            )
        )
    );

    submitValidationAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.SubmitValidationAction),
            mergeMap((action) =>
                this.partNumberRevisionApiService.submitValidationAction(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.SubmitValidationActionSuccess({ payload: results })),
                    catchError((error) => of(
                        partNumberRevisionActions.SubmitValidationActionFailure({ payload: error }),
                    ))
                )
            )
        )
    );

    addFileAssociation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.AddFileAssociation),
            mergeMap((action) =>
                this.partNumberRevisionApiService.addFileAssociation(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.AddFileAssociationSuccess({ payload: results })),
                    catchError((error) => of(
                            partNumberRevisionActions.AddFileAssociationFailure({ payload: error }),
                        ))
                    )
                )
        )
    );

    replaceAndAddFileAssociation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.ReplaceAndAddFileAssociation),
            mergeMap((action) =>
                this.partNumberRevisionApiService.replaceAndAddFileAssociation(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.ReplaceAndAddFileAssociationSuccess({ payload: results })),
                    catchError((error) => of(
                            partNumberRevisionActions.ReplaceAndAddFileAssociationFailure({ payload: error }),
                        ))
                    )
                )
        )
    );

    obsoleteFileAssociation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partNumberRevisionActions.ObsoleteFileAssociation),
            mergeMap((action) =>
                this.partNumberRevisionApiService.submitValidationAction(action.payload).pipe(
                    map((results) =>
                        partNumberRevisionActions.ObsoleteFileAssociationnSuccess({ payload: results })),
                    catchError((error) => of(
                        partNumberRevisionActions.ObsoleteFileAssociationFailure({ payload: error }),
                    ))
                )
            )
        )
    );
}

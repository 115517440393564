<div class="action-dialog__container">
	<div class="action-dialog__header">
		<fa-icon class="action-dialog__close-icon" 
			[icon]="faTimes" 
			mat-dialog-close 
			[mat-dialog-close]='false'>
		</fa-icon>
		<h2 class="type__h3--alt">{{ data.title }}</h2>
	</div>
	<div class="action-dialog__body">
		<div *ngIf="data.workflowType === WorkflowType.Obsolete">
			<ng-container *ngTemplateOutlet="obsoleteContent"></ng-container>
		</div>
		<div *ngIf="data.actionType === ActionType.Rejected">
			<ng-container *ngTemplateOutlet="rejectContent"></ng-container>
		</div>
	</div>
	<div class="action-dialog__footer">
		<button [disabled]="disableSubmitButton" mat-flat-button color="primary" (click)="submit()">{{ submitButtonText }}</button>
	</div>
</div>

<ng-template #obsoleteContent>
	<div class="action-dialog__body-obsolete">
		<div class="action-dialog__body-obsolete-message-text">
			The following file will be Obsoleted:
		</div>
		<div class="action-dialog__body-obsolete-message-value">
			{{ data.actionFileName }}
		</div>
		<div class="action-dialog__body-obsolete-error-message">
			{{ errorMessage }}
		</div>
	</div>
	<div class="action-dialog__validator-selection">
		<form [formGroup]="validatorFormGroup">
			<mat-form-field floatLabel="always">
				<mat-label>Validators</mat-label>
				<mat-select (selectionChange)="onValidatorSelectChange()" #validatorSelection formControlName="validatorSelection">
					<mat-option *ngFor="let v of data.validators" [value]="v.email">
						{{ v.userName }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</div>
</ng-template>


<ng-template #rejectContent>
	<div class="action-dialog__body-comments">
		<mat-form-field class="action-dialog__body-comments-text">
			<mat-label class="action-dialog__body-comments-text-input-label">Comments</mat-label>
			<form [formGroup]="commentsFormGroup">
				<textarea #commentsInput matInput formControlName="commentsInput" maxlength="1000" rows="8"></textarea>
            </form>
            <button *ngIf="commentsInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearCommentsInput()">
				<fa-icon class="action-dialog__body-clear-icon" [icon]="faTimes"></fa-icon>
            </button>
	  	</mat-form-field>
	</div>
</ng-template>

import { ConfigurationService } from './configuration.service';
import { EnvironmentService } from './environment.service';

export class ConfigurationFactory {
  static load(
    configurationService: ConfigurationService,
    environmentService: EnvironmentService
  ): (() => Promise<boolean>) {
    return (): Promise<boolean> => {
      return configurationService.getEnvironmentConfiguration()
        .toPromise()
        .then(config => {
          environmentService.registerEnvironmentConfig(config);
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });
    };
  }
}

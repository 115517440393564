import { createAction, props } from '@ngrx/store';
import { WorkflowRequest } from '@shared-models/workflow-request';
import { ApiError } from '@shared-models/api-error';
import { FileUploadDto } from '@shared-models/file-upload.dto';
import { PartNumberRevision } from '@shared-models/part-number-revision';
import { FileAssociation } from '@shared-models/file-association';
import { PaginationRequest } from '@shared-models/pagination-request';

export const GetPartNumberRevisions = createAction('[PART NUMBER REVISION] Getting part number revisions', props<{ payload: string }>());
export const GetPartNumberRevisionsSuccess = createAction('[PART NUMBER REVISION] Successfully got part number revisions', props<{ payload: any }>());
export const GetPartNumberRevisionsFailure = createAction('[PART NUMBER REVISION] Failed to get part number revisions', props<{ payload: ApiError }>());

export const GetPendingFiles = createAction('[PART NUMBER REVISION] Getting pending files',  props<{ payload: PaginationRequest }>());
export const GetPendingFilesSuccess = createAction('[PART NUMBER REVISION] Successfully got pending files', props<{ payload: FileAssociation }>());
export const GetPendingFilesFailure = createAction('[PART NUMBER REVISION] Failed to get pending files', props<{ payload: ApiError }>());

export const GetFilesToValidate = createAction('[PART NUMBER REVISION] Getting files to validate', props<{ payload: PartNumberRevision }>());
export const GetFilesToValidateSuccess = createAction('[PART NUMBER REVISION] Successfully got files to validate', props<{ payload: FileAssociation }>());
export const GetFilesToValidateFailure = createAction('[PART NUMBER REVISION] Failed to get files to validate', props<{ payload: ApiError }>());

export const SubmitValidationAction = createAction('[PART NUMBER REVISION] Submitting validation action', props<{ payload: WorkflowRequest }>());
export const SubmitValidationActionSuccess = createAction('[PART NUMBER REVISION] Successfully submitted validation action', props<{ payload: any }>());
export const SubmitValidationActionFailure = createAction('[PART NUMBER REVISION] Failed to submit validation action', props<{ payload: ApiError }>());

export const GetFilesOriginatedPendingValidation = createAction('[PART NUMBER REVISION] Getting files originated pending validation', props<{ payload: PartNumberRevision }>());
export const GetFilesOriginatedPendingValidationSuccess = createAction('[PART NUMBER REVISION] Successfully got files originated pending validation', props<{ payload: FileAssociation }>());
export const GetFilesOriginatedPendingValidationFailure = createAction('[PART NUMBER REVISION] Failed to get files originated pending validation', props<{ payload: ApiError }>());

export const GetOriginatorActiveFiles = createAction('[PART NUMBER REVISION] Getting originator files', props<{ payload: PartNumberRevision }>());
export const GetOriginatorActiveFilesSuccess = createAction('[PART NUMBER REVISION] Successfully got originator files', props<{ payload: FileAssociation }>());
export const GetOriginatorActiveFilesFailure = createAction('[PART NUMBER REVISION] Failed to get originator files', props<{ payload: ApiError }>());

export const GetOriginatorPastFiles = createAction('[PART NUMBER REVISION] Getting Past originator files', props<{ payload: PartNumberRevision }>());
export const GetOriginatorPastFilesSuccess = createAction('[PART NUMBER REVISION] Successfully got Past originator files', props<{ payload: FileAssociation }>());
export const GetOriginatorPastFilesFailure = createAction('[PART NUMBER REVISION] Failed to get Past originator files', props<{ payload: ApiError }>());

export const ClearPartNumberRevisionsData = createAction('[PART NUMBER REVISION] Clear ClearPartNumberRevisionsData');
export const ClearPendingFilesData = createAction('[[PENDING FILES] Clear ClearPendingFilesData');
export const ClearFilesToValidateData = createAction('[PART NUMBER REVISION] Clear ClearFilesToValidateData');
export const ClearFilesOriginatedPendingValidationData = createAction('[PART NUMBER REVISION] Clear ClearFilesOriginatedPendingValidationData');
export const ClearOriginatorFilesData = createAction('[PART NUMBER REVISION] Clear ClearOriginatorFilesData');
export const ClearReplaceAndAddFileAssociationSuccessData = createAction('[PART NUMBER REVISION] Clear ClearReplaceAndAddFileAssociationSuccessData');
export const ClearAddFileAssociationSuccessData = createAction('[PART NUMBER REVISION] Clear ClearAddFileAssociationSuccessData');
export const ClearObsoleteFileAssociationSuccessData = createAction('[PART NUMBER REVISION] Clear ClearObsoleteFileAssociationSuccessData');

export const AddFileAssociation = createAction('[PART NUMBER REVISION] Add file association', props<{ payload: FileUploadDto }>());
export const AddFileAssociationSuccess = createAction('[PART NUMBER REVISION] Successfully added file association', props<{ payload: any }>());
export const AddFileAssociationFailure = createAction('[PART NUMBER REVISION] Failed to add file association file', props<{ payload: ApiError }>());

export const ReplaceAndAddFileAssociation = createAction('[PART NUMBER REVISION] Replace and add file association', props<{ payload: FileUploadDto }>());
export const ReplaceAndAddFileAssociationSuccess = createAction('[PART NUMBER REVISION] Successfully replaced and added file association', props<{ payload: any }>());
export const ReplaceAndAddFileAssociationFailure = createAction('[PART NUMBER REVISION] Failed to replace and add file association', props<{ payload: ApiError }>());

export const ObsoleteFileAssociation = createAction('[PART NUMBER REVISION] Obsolete file association', props<{ payload: WorkflowRequest }>());
export const ObsoleteFileAssociationnSuccess = createAction('[PART NUMBER REVISION] Successfully obsoleted file association', props<{ payload: any }>());
export const ObsoleteFileAssociationFailure = createAction('[PART NUMBER REVISION] Failed to obsolete file association file', props<{ payload: ApiError }>());

<div class="file-upload__container">
  <div *ngIf="showDropArea" class="file-upload__container-drop-area" appFileUpload (fileDropped)="fileUploadHandler($event)">
    <input type="file" class="file-upload__container-drop-area-input" id="file-drop-reference" multiple
      (click)="resetFileInput($event)" (change)="fileUploadHandler($event.target['files'])" />
    <div class="file-upload__container-drop-area-text">
      <div class="file-upload__container-drop-area-text-dnd">Drag & drop or</div>
      <label class="file-upload__container-drop-area-text-browse" for="file-drop-reference">&nbsp;browse to
        upload</label>
    </div>
  </div>

  <div *ngIf="errorMessage" class="file-upload__container-error">{{ errorMessage }}</div>
  <div *ngIf="fileList.length" class="file-upload__container-files">
    <div class="file-upload__container-files-row-header">
      <span>File Name</span>
    </div>
    <div class="file-upload__container-files-row" *ngFor="let file of fileList; let i = index">
        <fa-icon [ngClass]="disableRemoveFile ? 'fa-light fa-circle-minus file-upload__container-files-row-icon--disabled' : 'fa-light fa-circle-minus file-upload__container-files-row-icon'" [icon]="faMinusCircle" (click)="removeFile(i)">
        </fa-icon>
      <div class="file-upload__container-files-row-name"> {{ file.name.split('/').slice(-1).pop() }} </div>
    </div>
  </div>
</div>
import { Component, Input } from '@angular/core';
import { PartNumberRevision } from '@shared-models/part-number-revision';
import { MatDialog } from '@angular/material/dialog';
import { FileSelectionComponent } from '@shared-components/file-selection/file-selection.component';
import { User } from '@shared-models/user';
import { FileSelectionData } from '@shared-components/file-selection/file-selection-data';
import { FileAssociationDetails } from '@shared-models/file-association';

@Component({
  selector: 'app-part-number-details',
  templateUrl: './part-number-details.component.html',
  styleUrls: ['./part-number-details.component.scss']
})
export class PartNumberDetailsComponent {
  @Input() partNumberRevision: PartNumberRevision;
  @Input() user:  User;
  @Input() validators: User[];
  @Input() filesToValidate: FileAssociationDetails[];

  constructor(
    public dialog: MatDialog,
  ) { }

  openFileSelection() {
    this.dialog.open(FileSelectionComponent, {
      panelClass: 'file-selection',
      disableClose: true,
      data: {
        partNumberRevision: this.partNumberRevision,
        user: this.user,
        validators: this.validators,
        filesToValidate: this.filesToValidate
      } as FileSelectionData
    });
  }

}

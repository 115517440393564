import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { SessionStateType } from '../models/session-state-type';
import { SessionStateService } from './session-state.service';

@Injectable({ providedIn: 'root' })
export class RouteGuard  {
  constructor(
    private authService: AuthService,
    private sessionStateService: SessionStateService
  ) { }

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.authService.isAuthenticated().subscribe(isAuthenticated => {
        if (!isAuthenticated) {
          this.triggerLoginRedirect(routeSnapshot);
          return observer.next(false);
        }
        return observer.next(true);
      }, err => {
        this.triggerLoginRedirect(routeSnapshot);
        return observer.next(false);
      });
    });
  }

  triggerLoginRedirect(routeSnapshot: ActivatedRouteSnapshot) {
    if (routeSnapshot.queryParams.attachmentKey) {
      this.sessionStateService.setItem(SessionStateType.ProductFileKey, routeSnapshot.queryParams.attachmentKey);
    }
    this.authService.logout();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStateType } from 'src/app/shared/models/session-state-type';
import { SessionStateService } from 'src/app/shared/services/session-state.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedModalService } from '@shared-services/shared-modal.service';
import { FailureModalData } from '@shared/components/failure-modal/failure-modal-data';

@Component({
  selector: 'app-auth',
  template: ''
})
export class AuthComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private sessionStateService: SessionStateService,
    private sharedModalService: SharedModalService
  ) { }

  ngOnInit(): void {
    const productFileKey = this.sessionStateService.getItem(SessionStateType.ProductFileKey);

    if (productFileKey) {
      this.router.navigate(['/download'], { queryParams: { productFileKey }});
    } else {

      const data = {
        body: 'The product file key is missing from the request. Make sure that the URL is correct and then try again. If you are still unable to download the file, please submit a support ticket.'
      } as FailureModalData;

      this.sharedModalService.openFailureModal(data);

    }
  }

}

import { State } from '@shared-store/app.store';

export function selectUser(state: State) {
    return state.user.user;
   }

export function selectUserError(state: State) {
    return state.user.userError;
}

export function selectValidators(state: State) {
    return state.user.validators;
}

export function selectUserLoading(state: State) {
    return state.user.userLoading;
}
